import React from 'react';
import '../stylesheets/Navbar.css';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ userSignedIn, csrfToken, currentUser, ...props }) => {

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        {userSignedIn ? (
          <a onClick={() => handleNavigation('/pools')} style={{ cursor: 'pointer' }}>🏟️ Quinielas de Futbol</a>
        ) : (
          <a onClick={() => handleNavigation('/')} style={{ cursor: 'pointer' }}>🏟️ Quinielas de Futbol</a>
        )}
      </div>
      <button className="navbar-toggler" onClick={() => document.getElementById('navbarMenu').classList.toggle('is-active')}>&#9776;</button>
      <div className="navbar-menu" id="navbarMenu">
        <a href='/pools' style={{ cursor: 'pointer' }}>Quinielas</a>
        {userSignedIn && <a onClick={() => handleNavigation('/profile')} style={{ cursor: 'pointer' }}>Perfil</a>}
        {userSignedIn ? (
          <form action="/users/sign_out" method="post" className="logout-form">
            <input type="hidden" name="_method" value="delete" />
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <button type="submit" className="logout-link">Cerrar Sesión</button>
          </form>
        ) : (
          <>
            <a onClick={() => handleNavigation('/users/sign_in')} style={{ cursor: 'pointer' }}>Iniciar Sesión</a>
            <a onClick={() => handleNavigation('/users/sign_up')} style={{ cursor: 'pointer' }}>Registrarse</a>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;