import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLeagues, setMatches, setPools } from '../store/poolsReducer';

const LeagueButton = ({ league, isSelected, onClick }) => (
  <button
    type="button" // Asegúrate de que el botón no sea de tipo submit
    onClick={() => onClick(league.id)}
    style={{
      padding: '10px 15px',
      border: isSelected ? '1px solid #4CAF50' : '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: isSelected ? '#E8F5E9' : 'white',
      color: isSelected ? '#4CAF50' : 'black',
      cursor: 'pointer',
      position: 'relative',
      marginRight: '10px',
    }}
  >
    {league.name}
    {isSelected && (
      <span style={{
        position: 'absolute',
        top: '-8px',
        right: '-8px',
        backgroundColor: '#4CAF50',
        color: 'white',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
      }}>
        ✓
      </span>
    )}
  </button>
);

const MatchDisplay = ({ match, onSelect, isSelected }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      timeZone: 'CST' 
    });
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      borderBottom: '1px solid #e0e0e0',
      backgroundColor: 'white',
    }}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => onSelect(match.id)}
        style={{ marginRight: '15px' }}
      />
      <img src={match.home_team_logo} alt={match.home_team} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
      <span style={{ fontWeight: 'bold', marginRight: '10px', flex: 1 }}>{match.home_team}</span>
      <span style={{ margin: '0 10px' }}>vs</span>
      <img src={match.away_team_logo} alt={match.away_team} style={{ width: '30px', height: '30px', marginLeft: '10px', marginRight: '10px' }} />
      <span style={{ fontWeight: 'bold', marginLeft: '10px', flex: 1 }}>{match.away_team}</span>
      <span style={{ marginLeft: '20px', color: '#666', whiteSpace: 'nowrap' }}>
        {formatDate(match.date)}
      </span>
    </div>
  );
};

function PoolNew(props) {
  console.log('Props:', props);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [prize, setPrize] = useState('');
  const [errors, setErrors] = useState([]);
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [useTieBreaker, setUseTieBreaker] = useState(false);
  const [tieBreakerGoals, setTieBreakerGoals] = useState(props.pool ? props.pool.tie_breaker_goals : 0);
  const [predictionType, setPredictionType] = useState('score');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useSelector(state => state.pools.matches) || [];
  const leagues = useSelector(state => state.pools.leagues) || [];

  useEffect(() => {
    if (props.leagues) {
      dispatch(setLeagues(props.leagues));
    }

    // Si estás editando, prellenar los campos con datos existentes
    if (props.pool) {
      setTitle(props.pool.title);
      setDescription(props.pool.description);
      setPrize(props.pool.prize);
      setSelectedLeagues(props.pool.league_ids || []);
      setSelectedMatches(props.pool.match_ids || []);
      setUseTieBreaker(props.pool.use_tie_breaker);
      setTieBreakerGoals(props.pool.tie_breaker_goals || 0);
      setPredictionType(props.pool.prediction_type || 'score');
    }
  }, [props.leagues, dispatch]);

  const handleLeagueSelection = async (leagueId) => {
    let updatedSelectedLeagues;
    if (selectedLeagues.includes(leagueId)) {
      updatedSelectedLeagues = selectedLeagues.filter(id => id !== leagueId);
    } else {
      updatedSelectedLeagues = [...selectedLeagues, leagueId];
    }
    setSelectedLeagues(updatedSelectedLeagues);

    const url = `/api/fixtures?league=${updatedSelectedLeagues.join(',')}`;
    console.log(`Request URL: ${url}`);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('API response:', data);

      if (data.length > 0) {
        const newMatches = data.map(match => ({
          id: match.fixture.id,
          home_team: match.teams.home.name,
          away_team: match.teams.away.name,
          date: match.fixture.date,
          status: match.fixture.status.short,
          home_team_logo: match.teams.home.logo,
          away_team_logo: match.teams.away.logo,
          selected: false,
          league_id: leagueId
        }));
        dispatch(setMatches(newMatches));
      } else {
        dispatch(setMatches([]));
      }

      if (!updatedSelectedLeagues.includes(leagueId)) {
        dispatch(setMatches(matches.filter(match => match.league_id !== leagueId)));
      }
    } catch (error) {
      console.error('Error fetching matches:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/pools', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': props.csrfToken,
        },
        body: JSON.stringify({
          pool: {
            title,
            description,
            prize,
            match_ids: selectedMatches,
            league_ids: selectedLeagues,
            use_tie_breaker: useTieBreaker,
            tie_breaker_goals: useTieBreaker ? tieBreakerGoals : 0,
            prediction_type: predictionType,
          },
        }),
      });

      if (!response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const errorData = await response.json();
          if (errorData.errors) {
            setErrors(errorData.errors);
          } else {
            setErrors([errorData.error || 'An unexpected error occurred.']);
          }
        } else {
          setErrors(['An unexpected error occurred. The server returned an invalid response.']);
        }
        throw new Error('Network response was not ok.');
      }

      navigate('/organizer_dashboard');
    } catch (error) {
      console.error('Error:', error);
      setErrors([`An unexpected error occurred: ${error.message}`]);
    }
  };

  const handleMatchSelection = (id) => {
    setSelectedMatches((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((matchId) => matchId !== id)
        : [...prevSelected, id]
    );
  };

  const toggleTieBreaker = () => {
    setUseTieBreaker(!useTieBreaker);
    if (useTieBreaker) {
      setTieBreakerGoals(0);
    }
  };

  const handlePredictionTypeSelection = (type) => {
    setPredictionType(type);
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f0f0f0', borderRadius: '10px'}}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Create a New Pool</h1>
      {errors.length > 0 && (
        <div style={{ backgroundColor: '#ffebee', border: '1px solid #ef9a9a', borderRadius: '4px', padding: '10px', marginBottom: '20px', width: '95%' }}>
          <h2 style={{ color: '#c62828', marginBottom: '10px' }}>{errors.length} errors prohibited this pool from being saved:</h2>
          <ul style={{ paddingLeft: '20px' }}>
            {errors.map((error, index) => (
              <li key={index} style={{ color: '#c62828' }}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="title" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Título</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '95%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
            required
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="description" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Descripción</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: '95%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', minHeight: '100px' }}
            required
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="prize" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Premio</label>
          <input
            type="text"
            id="prize"
            value={prize}
            onChange={(e) => setPrize(e.target.value)}
            style={{ width: '95%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
            required
          />
        </div>

        {/* Selector de Tipo de Predicción */}
        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Tipo de Predicción</h2>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div
              onClick={() => handlePredictionTypeSelection('score')}
              style={{
                border: predictionType === 'score' ? '2px solid #4CAF50' : '2px solid #ccc',
                borderRadius: '4px',
                padding: '10px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <img src="/path/to/score-image.png" alt="Score Prediction" style={{ width: '100%' }} />
              <p>Por Marcador</p>
            </div>
            <div
              onClick={() => handlePredictionTypeSelection('result')}
              style={{
                border: predictionType === 'result' ? '2px solid #4CAF50' : '2px solid #ccc',
                borderRadius: '4px',
                padding: '10px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '150px',
              }}
            >
              <img src="/path/to/result-image.png" alt="Result Prediction" style={{ width: '100%' }} />
              <p>Por Resultado</p>
            </div>
          </div>
        </div>

        {/* Checkbox for Tie Breaker */}
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="use_tie_breaker" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <input
              type="checkbox"
              id="use_tie_breaker"
              checked={useTieBreaker}
              onChange={toggleTieBreaker}
              style={{ marginRight: '10px' }}
            />
            Usar Método de Desempate
          </label>
        </div>

        {/* Tiebreaker Goals Input */}
        {useTieBreaker && (
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="tie_breaker_goals" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Total de Goles (Desempate)</label>
            <input
              type="number"
              id="tie_breaker_goals"
              value={tieBreakerGoals}
              onChange={(e) => setTieBreakerGoals(e.target.value)}
              style={{ width: '95%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
              required
            />
          </div>
        )}
        
        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Ligas</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {leagues.map((league) => (
              <LeagueButton
                key={league.id}
                league={league}
                isSelected={selectedLeagues.includes(league.id)}
                onClick={handleLeagueSelection}
              />
            ))}
          </div>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Partidos</h2>
          <div style={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: 'white' }}>
            {Array.isArray(matches) && matches.length > 0 ? (
              matches.map((match) => (
                <MatchDisplay
                  key={match.id} // Asegúrate de que el key sea único
                  match={match}
                  onSelect={handleMatchSelection}
                  isSelected={selectedMatches.includes(match.id)}
                />
              ))
            ) : (
              <p style={{ padding: '10px' }}>Selecciona una liga para continuar...</p>
            )}
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          <button 
            type="submit" 
            style={{ 
              backgroundColor: '#4a90e2', 
              color: 'white', 
              padding: '10px 20px', 
              border: 'none', 
              borderRadius: '4px', 
              cursor: 'pointer',
              fontSize: '16px'
            }}
          >
            {props.pool ? 'Actualizar Quiniela' : 'Crear Quiniela'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default PoolNew;