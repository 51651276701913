import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import '../stylesheets/Leaderboard.css';

const Leaderboard = ({ pool, userSignedIn, csrfToken }) => {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (pool && pool.id) {
      fetch(`/pools/${pool.id}/leaderboard.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data && data.participants) {
            setParticipants(data.participants);
          }
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  }, [pool]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!participants || participants.length === 0) return (
    <>
      <Navbar userSignedIn={userSignedIn} csrfToken={csrfToken} />
      <div className="tabs">
        <Link to={`/pools/${pool.id}`} className={`tab ${location.pathname === `/pools/${pool.id}` ? 'active' : ''}`}>Partidos</Link>
        <Link to={`/pools/${pool.id}/leaderboard`} className={`tab ${location.pathname === `/pools/${pool.id}/leaderboard` ? 'active' : ''}`}>Leaderboard</Link>
      </div>
      <div className="container">
        <h1 className="title">Leaderboard</h1>
        <p style={{
          textAlign: 'center',
          marginTop: '20px',
          fontSize: '1rem',
          color: 'white'
        }}>No hay participantes todavía.</p>
      </div>
    </>
  );

  const groupedParticipants = participants.reduce((acc, participant) => {
    if (!acc[participant.id]) {
      acc[participant.id] = { user: participant, sets: {} };
    }
    participant.predictions.forEach(prediction => {
      if (!acc[participant.id].sets[prediction.name]) {
        acc[participant.id].sets[prediction.name] = [];
      }
      acc[participant.id].sets[prediction.name].push(prediction);
    });
    return acc;
  }, {});

  const calculateTotalPoints = (predictions) => {
    return predictions.reduce((acc, prediction) => acc + (prediction.points || 0), 0);
  };

  const sortedParticipantsList = Object.keys(groupedParticipants).flatMap(userId => {
    return Object.keys(groupedParticipants[userId].sets).map(setName => {
      return {
        user: groupedParticipants[userId].user,
        setName,
        totalPoints: calculateTotalPoints(groupedParticipants[userId].sets[setName])
      };
    });
  }).sort((a, b) => b.totalPoints - a.totalPoints);

  const getRankEmoji = (rank) => {
    switch (rank) {
      case 1:
        return '🥇';
      case 2:
        return '🥈';
      case 3:
        return '🥉';
      default:
        return '';
    }
  };

  return (
    <>
      <Navbar userSignedIn={userSignedIn} csrfToken={csrfToken} />
      <div className="tabs">
        <Link to={`/pools/${pool.id}`} className={`tab ${location.pathname === `/pools/${pool.id}` ? 'active' : ''}`}>Partidos</Link>
        <Link to={`/pools/${pool.id}/leaderboard`} className={`tab ${location.pathname === `/pools/${pool.id}/leaderboard` ? 'active' : ''}`}>Leaderboard</Link>
      </div>
      <div className="container">
        <h1 className="title">Leaderboard</h1>
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Puntos</th>
              { pool.use_tie_breaker && <th>Goles</th> }
            </tr>
          </thead>
          <tbody>
            {sortedParticipantsList.map((participant, index) => (
              <tr key={`${participant?.user?.id}-${participant?.setName}`} className={`rank-${index + 1}`}>
                <td>{ index + 1 } {getRankEmoji(index + 1)}</td>
                <td className='profile-picture-td'>
                  {participant?.user?.profile_picture_url !== null ? (
                    <img src={ participant?.user?.profile_picture_url } alt={ participant?.user?.username } className="profile-picture" />
                  ) : (
                    <div className="profile-placeholder">{participant?.user?.username?.charAt(0).toUpperCase()}</div>
                  )}
                  {/* { participant?.user?.username?.length > 18 ? participant?.user?.username?.substring(0, 13) + '...' : participant?.user?.username } */}
                  <div>{participant?.setName}</div>
                </td>
                <td>{ participant?.totalPoints }</td>
                {pool.use_tie_breaker && <td>{ participant?.tie_breaker_goals }</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Leaderboard;