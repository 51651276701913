import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../store';
import PoolsIndex from '../components/PoolsIndex';
import PoolShow from '../components/PoolShow';
import Leaderboard from '../components/Leaderboard';
import UserProfile from '../components/UserProfile';
import UserEditProfile from '../components/UserEditProfile';
import LandingPage from '../components/LandingPage';
import UserPoolsIndex from '../components/UserPoolsIndex';
import RegistrationForm from '../components/RegistrationForm';
import OrganizerDashboard from '../components/OrganizerDashboard';
import PoolNew from '../components/PoolNew';

import '../../assets/stylesheets/application.css';
import '../../assets/stylesheets/auth.css';

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.getElementById('react-root');
  const registrationFormElement = document.getElementById('react-registration-form');
  const poolNewElement = document.getElementById('react-pool-new');

  if (rootElement) {
    const props = JSON.parse(rootElement.getAttribute('data-props'));

    // console.log("Props passed to React component:", props);

    const root = createRoot(rootElement);

    root.render(
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage {...props} />} />
            {props.userSignedIn ? (
              props.currentUser && props.currentUser.organizer ? (
                <>
                  <Route path="/organizer_dashboard" element={<OrganizerDashboard {...props} />} />
                  <Route path="/pools/new" element={<PoolNew {...props} />} />
                  <Route path="/pools/:id" element={<PoolShow {...props} />} />
                  <Route path="/pools/:id/leaderboard" element={<Leaderboard pool={props.pool} {...props} />} />
                  <Route path="/profile" element={<UserProfile user={props.currentUser} {...props} />} />
                  <Route path="/profile/edit" element={<UserEditProfile user={props.currentUser} csrfToken={props.csrfToken} {...props} />} />
                  <Route path="/users/:username/pools" element={<UserPoolsIndex {...props} />} />
                  <Route path="*" element={<Navigate to="/organizer_dashboard" />} />
                </>
              ) : (
                <>
                  <Route path="/pools" element={<PoolsIndex {...props} />} />
                  <Route path="/pools/:id" element={<PoolShow {...props} />} />
                  <Route path="/pools/:id/" element={<PoolShow {...props} />} />
                  <Route path="/pools/:id/leaderboard" element={<Leaderboard pool={props.pool} {...props} />} />
                  <Route path="/profile" element={<UserProfile user={props.currentUser} {...props} />} />
                  <Route path="/profile/edit" element={<UserEditProfile user={props.currentUser} csrfToken={props.csrfToken} {...props} />} />
                  <Route path="/users/:username/pools" element={<UserPoolsIndex {...props} />} />
                  <Route path="*" element={<Navigate to="/pools" />} />
                </>
              )
            ) : (
              <>
                <Route path="/pools/:id" element={<PoolShow {...props} />} />
                <Route path="/pools/:id/leaderboard" element={<Leaderboard pool={props.pool} {...props} />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
          </Routes>
        </Router>
      </Provider>
    );
  }

  if (registrationFormElement) {
    const props = JSON.parse(registrationFormElement.getAttribute('data-props'));
    const root = createRoot(registrationFormElement);
    root.render(<RegistrationForm {...props} />);
  }

  // if (poolNewElement) {
  //   const node = document.getElementById('react-pool-new');
  //   console.log("node:", node);
  //   const props = JSON.parse(poolNewElement.getAttribute('data-props'));
  //   console.log("Props passed to React component:", props);

  //   const root = createRoot(poolNewElement);
  //   root.render(<PoolNew {...props} />);
  // }

  // Manejo de campos de organizador
  const organizerCheckbox = document.querySelector("input#user_organizer");
  const organizerFields = document.getElementById("organizer-fields");

  if (organizerCheckbox) {
    organizerCheckbox.addEventListener("change", function() {
      if (this.checked) {
        organizerFields.style.display = "block";
      } else {
        organizerFields.style.display = "none";
      }
    });
  }
});