import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../stylesheets/PoolShow.css';
import MatchModal from './modals/MatchModal';
import Navbar from './Navbar';
import { setPool } from '../store/poolsReducer';

const PoolShow = ({ userIsCreator, userIsParticipant, userSignedIn, notice, currentUser, csrfToken, pool, ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [editingPrediction, setEditingPrediction] = useState(null);
  const [showPredictionForm, setShowPredictionForm] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pool) {
      dispatch(setPool(pool));
      setLoading(false);
    }
  }, [pool, dispatch]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (!pool.id) {
    return <p>No se encontraron datos para esta quiniela.</p>;
  }

  const uniqueMatches = pool.matches ? pool.matches.filter((match, index, self) => self.findIndex(m => m.id === match.id) === index) : [];

  const isLongName = name => name && name.length > 10;

  const openModal = (match) => {
    setSelectedMatch(match);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMatch(null);
  };

  const handleEditClick = (prediction) => {
    setEditingPrediction(prediction);
  };

  const handleCancelClick = () => {
    setEditingPrediction(null);
  };

  const compareMatchesByDate = (a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  };

  const displayMatchInfo = (match) => {
    if (match.status === 'NS' || match.status === 'TBD') {
      return formatDateTime(match.date);
    } else if (match.status === 'FT') {
      return 'FT';
    } else if (match.status === 'PEN') {
      return 'PEN';
    } else if (match.status === '1H' || match.status === '2H') {
      return match.elapsed ? `${match.elapsed}'` : '0\'';
    } else {
      return match.elapsed ? `${match.elapsed}'` : '0\'';
    }
  };

  const handleParticipateClick = () => {
    if (userSignedIn) {
      window.location.href = `/pools/${pool.id}/join?user_return_to=/pools/${pool.id}`;
    } else {
      const referralCode = pool.creator.referral_code;
      window.location.href = `/users/sign_up?referral_code=${referralCode}&user_return_to=/pools/${pool.id}`;
    }
  };

  const handleCreatePredictionClick = () => {
    setShowPredictionForm(true);
  };

  const handleLeaderboardClick = (e) => {
    e.preventDefault();
    navigate(`/pools/${pool.id}/leaderboard`);
  };

  const groupedPredictions = currentUser?.predictions?.reduce((acc, prediction) => {
    if (!acc[prediction.name]) {
      acc[prediction.name] = [];
    }
    acc[prediction.name].push(prediction);
    return acc;
  }, {});

  const calculateTotalPoints = (predictions) => {
    return predictions.reduce((acc, prediction) => acc + (prediction.points || 0), 0);
  };

  const getResultLabel = (result) => {
    switch (result) {
      case 'home_win':
        return 'Local';
      case 'draw':
        return 'Empate';
      case 'away_win':
        return 'Visitante';
      default:
        return '';
    }
  };

  const getResultClass = (result, predictionResult) => {
    return result === predictionResult ? 'selected-result' : '';
  };

  return (
    <>
      <Navbar userSignedIn={userSignedIn} csrfToken={csrfToken} />
      <div className="container">
        <h1 className="title">{pool.title}</h1>
        <h2 className='title'>{pool.description}</h2>
        <h3 className='title'>Premio: {pool.prize}</h3>
        <div className="tabs">
          <Link to={`/pools/${pool.id}`} className={`tab ${location.pathname === `/pools/${pool.id}` ? 'active' : ''}`}>Partidos</Link>
          <Link to="#" onClick={handleLeaderboardClick} className={`tab ${location.pathname === `/pools/${pool.id}/leaderboard` ? 'active' : ''}`}>Leaderboard</Link>
        </div>

        {location.pathname === `/pools/${pool.id}` && (
          <>
            {!userIsParticipant ? (
              <button
                className="main-button bounce"
                onClick={handleParticipateClick}
                disabled={pool.isStarted || pool.isFinished}
                style={{
                  backgroundColor: pool.isStarted || pool.isFinished ? 'gray' : '',
                  cursor: pool.isStarted || pool.isFinished ? 'not-allowed' : 'pointer'
                }}
              >
                Participar
              </button>
            ) : (
              <button
                className="main-button bounce"
                onClick={handleCreatePredictionClick}
                disabled={pool.isStarted || pool.isFinished}
                style={{
                  backgroundColor: pool.isStarted || pool.isFinished ? 'gray' : '',
                  cursor: pool.isStarted || pool.isFinished ? 'not-allowed' : 'pointer'
                }}
              >
                Crear predicción
              </button>
            )}

            {userIsParticipant && showPredictionForm && (
              <>
                <h3 className="subtitle">Completa tus predicciones</h3>
                <form action="/predictions" method="post" className="predictions-form">
                  <input type="hidden" name="authenticity_token" value={csrfToken} />
                  {uniqueMatches.map(match => (
                    <div key={match.id} className="prediction-item">
                      <div className="prediction-teams">
                        <div className="team">
                          <img src={match.home_team_logo} alt={match.home_team} className="team-logo" />
                          <span className={`team-name ${isLongName(match.home_team) ? 'long-name' : ''}`}>{match.home_team}</span>
                        </div>
                        {pool.prediction_type === 'score' ? (
                          <>
                            <input type="number" name="prediction[home_team_score][]" className="score-input" />
                            -
                            <input type="number" name="prediction[away_team_score][]" className="score-input" />
                          </>
                        ) : (
                          <div className="result-options">
                            <label>
                              <input type="radio" name={`prediction[result][${match.id}]`} value="home_win" /> Local
                            </label>
                            <label>
                              <input type="radio" name={`prediction[result][${match.id}]`} value="draw" /> Empate
                            </label>
                            <label>
                              <input type="radio" name={`prediction[result][${match.id}]`} value="away_win" /> Visitante
                            </label>
                          </div>
                        )}
                        <div className="team">
                          <span className={`team-name ${isLongName(match.away_team) ? 'long-name' : ''}`}>{match.away_team}</span>
                          <img src={match.away_team_logo} alt={match.away_team} className="team-logo" />
                        </div>
                      </div>
                      <input type="hidden" name="prediction[match_id][]" value={match.id} />
                      <input type="hidden" name="prediction[pool_id][]" value={pool.id} />
                    </div>
                  ))}
                  {pool.use_tie_breaker && (
                    <div className="tie-breaker-container">
                      <label className="tie-breaker-label">Total de goles: </label>
                      <input type="number" name="prediction[tie_breaker_goals]" placeholder="0" className="tie-breaker-input" />
                    </div>
                  )}
                  <button type="submit" className="submit-button">Guardar predicciones</button>
                </form>
              </>
            )}

{userIsParticipant && (
              <>
                {editingPrediction ? (
                  <div className="edit-prediction-form">
                    <h3 className="subtitle">Editar Predicción</h3>
                    <form action={`/predictions/${editingPrediction.id}`} method="post">
                      <input type="hidden" name="_method" value="patch" />
                      <input type="hidden" name="authenticity_token" value={csrfToken} />
                      <div className="prediction-item">
                        <div className="prediction-teams">
                          <div className="team">
                            <img src={editingPrediction.match.home_team_logo} alt={editingPrediction.match.home_team} className="team-logo" />
                            <span className={`team-name ${isLongName(editingPrediction.match.home_team) ? 'long-name' : ''}`}>{editingPrediction.match.home_team}</span>
                          </div>
                          {pool.prediction_type === 'score' ? (
                            <>
                              <input type="number" name="prediction[home_team_score]" defaultValue={editingPrediction.home_team_score} className="score-input" /> - <input type="number" name="prediction[away_team_score]" defaultValue={editingPrediction.away_team_score} className="score-input" />
                            </>
                          ) : (
                            <div className="result-options">
                              <label>
                                <input type="radio" name={`prediction[result][${editingPrediction.match.id}]`} value="home_win" defaultChecked={editingPrediction.result === 'home_win'} /> Local
                              </label>
                              <label>
                                <input type="radio" name={`prediction[result][${editingPrediction.match.id}]`} value="draw" defaultChecked={editingPrediction.result === 'draw'} /> Empate
                              </label>
                              <label>
                                <input type="radio" name={`prediction[result][${editingPrediction.match.id}]`} value="away_win" defaultChecked={editingPrediction.result === 'away_win'} /> Visitante
                              </label>
                            </div>
                          )}
                          <div className="team">
                            <span className={`team-name ${isLongName(editingPrediction.match.away_team) ? 'long-name' : ''}`}>{editingPrediction.match.away_team}</span>
                            <img src={editingPrediction.match.away_team_logo} alt={editingPrediction.match.away_team} className="team-logo" />
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="submit-button">Guardar cambios</button>
                      <button type="button" className="cancel-button" onClick={handleCancelClick}>Cancelar</button>
                    </form>
                  </div>
                ) : (
                  <>
                    <div className='header'>
                      {Object.keys(groupedPredictions).length > 0 && <h3 className="subtitle">Tus Predicciones</h3> }
                      {Object.keys(groupedPredictions).map((setName, index) => (
                        <details key={index} className="prediction-set">
                          <summary className="prediction-set-name">
                            <span>{setName}</span>
                            <div className="right-content">
                              <strong>{calculateTotalPoints(groupedPredictions[setName])} puntos</strong>
                              <span>&#9660;</span>
                            </div>
                          </summary>
                          <ul className="predictions-list">
                            {uniqueMatches.map(match => {
                              const predictions = groupedPredictions[setName].filter(p => p.match_id === match.id);
                              return predictions.map(prediction => (
                                <li key={prediction.id} className="prediction-item">
                                  <div className="prediction-teams">
                                    {pool.prediction_type === 'score' ? (
                                      <>
                                        <div className="team">
                                          <img src={match.home_team_logo} alt={match.home_team} className="team-logo" />
                                          <span className={`team-name ${isLongName(match.home_team) ? 'long-name' : ''}`}>{match.home_team}</span>
                                          <span className="score">{prediction.home_team_score}</span>
                                          -
                                          <span className="score">{prediction.away_team_score}</span>
                                          <span className={`team-name ${isLongName(match.away_team) ? 'long-name' : ''}`}>{match.away_team}</span>
                                          <img src={match.away_team_logo} alt={match.away_team} className="team-logo" />
                                        </div>
                                      </>
                                    ) : (
                                      <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}>
                                        <div style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          background: '#444242',
                                          borderRadius: '4px',
                                          marginBottom: '6px',
                                          padding: '4px',
                                          width: '65px',
                                        }}>
                                          <span className="score" style={{fontSize: '15px'}}>{match.home_team_score || 0}</span>-<span className="score" style={{fontSize: '15px'}}>{match.away_team_score || 0}</span>
                                        </div>
                                        <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                                          <div className="team">
                                            <img src={match.home_team_logo} alt={match.home_team} className="team-logo" />
                                            <span className={`result ${getResultClass('home_win', prediction.result)}`}>Local</span>
                                          </div>
                                          <div className="score-container-my-predictions">
                                            <span className={`result ${getResultClass('draw', prediction.result)}`}>Empate</span>
                                          </div>
                                          <div className="team">
                                            <span className={`result ${getResultClass('away_win', prediction.result)}`}>Visitante</span>
                                            <img src={match.away_team_logo} alt={match.away_team} className="team-logo" />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className='bottom-elements-container'>
                                    <div className="prediction-details">
                                      {(match.status === 'FT' || match.status === 'PEN') && prediction && (
                                        <>
                                          <span className={
                                            prediction.points === 1 
                                              ? 'correct-prediction' 
                                              : 'incorrect-prediction'
                                          }>
                                            {prediction.points === 1 ? '✅' : '❌'}
                                          </span>
                                          <span className={
                                            prediction.points === 5 
                                              ? 'detail-points-5' 
                                              : (prediction.points === 3 ? 'detail-points-3' : 'detail-points')
                                          }>
                                            +{prediction.points}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                    {/* Edit button */}
                                    {/* {(match.status === 'NS' || match.status === 'TBD') && (
                                      <div className="edit-button-container">
                                        <button className="edit-button" onClick={() => handleEditClick({ ...prediction, match })}>Editar</button>
                                      </div>
                                    )} */}
                                  </div>
                                </li>
                              ));
                            })}
                          </ul>
                        </details>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}

            <h2 className="subtitle">Partidos</h2>
            {uniqueMatches && uniqueMatches.length > 0 ? (
              <ul className="predictions-list">
                {uniqueMatches.map(match => (
                  <li key={match.id} className="prediction-item">
                    <span className={(match.status === '1H' || match.status === '2H') ? 'elapsed-status-live' : 'elapsed-status'}>{
                      displayMatchInfo(match)
                    }</span>
                    <div className="prediction-teams">
                      <div className="team">
                        <img src={match.home_team_logo} alt={match.home_team} className="team-logo" />
                        <span className={`team-name ${isLongName(match.home_team) ? 'long-name' : ''}`}>{match.home_team}</span>
                          <span className="score">{match.home_team_score || 0}</span>
                          -
                          <span className="score">{match.away_team_score || 0}</span>
                          <span className={`team-name ${isLongName(match.away_team) ? 'long-name' : ''}`}>{match.away_team}</span>
                          <img src={match.away_team_logo} alt={match.away_team} className="team-logo" />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="no-matches">No matches available.</p>
            )}
          </>
        )}

        {userIsCreator && (
          <div className="creator-actions">
            <a href="/pools">Regresar al Panel de Administración</a> | 
            <form action={`/pools/${pool.id}`} method="post" style={{ display: 'inline' }}>
              <input type="hidden" name="_method" value="delete" />
            </form>
          </div>
        )}

        <MatchModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          selectedMatch={selectedMatch}
          participants={pool.participants || []}
          currentUser={currentUser}
        />
      </div>
    </>
  );
};

export default PoolShow;